.main-time {
  padding: 0px 10px 10px 10px;
  color: #2873ee;
}

.inner-time {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.dod-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dod-label {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: red;
}

.big-sale-text {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.img-timer {
  margin-right: 5px;
  height: 20px;
  width: 16px;
}

#test {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

/* .sale_text{
    margin-left: auto;
    font-size: 20px;
    text-align: center;
}

.btn-sale-is-live{
    background: #ffffff;
    color: red;
    padding: 10px 12px;
    font-size: 12px;
    border-radius: 4px;
    text-transform: inherit;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0;
    border: 1px solid rgb(241, 27, 27);
    outline: 0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-align: center;
    font-weight: bold;
    line-height: 14px;
} */

.category-box::-webkit-scrollbar {
  height: 0;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  background: #343a4033;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.rating_box {
  font-size: 14px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  width: max-content;
  padding: 2px 8px;
  border-radius: 16px;
  background: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.rating_box i {
  font-size: 11px;
  margin-left: 5px;
  color: #228b22;
}
.menu {
  user-select: none;
  padding: 8px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  counter-reset: menu;
  background: rgb(2, 1, 1);
}
marquee span {
  font-size: 18px;
}
@media screen and (min-width: 512px) {
    /* .sliders_main {
      height: 500px;
    } */
  .keen-slider {
    max-height: 500px;
    height: 100%;
  }
  /* .thumbnail .keen-slider__slide{
    height: auto !important;
  } */
}






